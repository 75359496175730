function ConfirmIt(array)
{
    this.message = array.message
    this.confirm = array.confirm || 'Confirm'
    this.cancel = array.cancel || 'Cancel'
    this.onConfirm = array.onConfirm || null
    this.onCancel = array.onCancel || null

    this.cfmBuildStructure = function()
    {
        // Creating layer.
        this.cfmLayer = document.createElement('div')
        this.cfmLayer.classList.add('cfm-layer')

        // Creating container.
        this.cfmBoxContainer = document.createElement('div')
        this.cfmBoxContainer.classList.add('cfm-box-container')

        // Creating box.
        this.cfmBox = document.createElement('div')
        this.cfmBox.classList.add('cfm-box')

        // Creating message container.
        this.cfmMessage = document.createElement('span')
        this.cfmMessage.classList.add('cfm-message')

        // Creating buttons.
        this.cfmBtns = document.createElement('ul')
        this.cfmBtns.classList.add('cfm-btns')
        // Confirm button.
        this.cfmBtnConfirm = document.createElement('li')
        this.cfmBtnConfirm.classList.add(
            'cfm-btn',
            'cfm-btn-confirm'
        )
        // Cancel button.
        this.cfmBtnCancel = document.createElement('li')
        this.cfmBtnCancel.classList.add(
            'cfm-btn',
            'cfm-btn-cancel'
        )

        // Appending new instance to the body.
        this.cfmAppendStructure()
    }

    this.cfmAppendStructure = function()
    {
        // Appending buttons labels.
        this.cfmBtnConfirm.appendChild(document.createTextNode(this.confirm))
        this.cfmBtnCancel.appendChild(document.createTextNode(this.cancel))

        // Appending buttons.
        this.cfmBtns.appendChild(this.cfmBtnCancel)
        this.cfmBtns.appendChild(this.cfmBtnConfirm)

        // Appending message to its container.
        // this.cfmMessage.appendChild(document.createTextNode(this.message))
        this.cfmMessage.innerHTML = this.message

        // Appending message and buttons to the box.
        this.cfmBox.appendChild(this.cfmMessage)
        this.cfmBox.appendChild(this.cfmBtns)

        // Appending box to its container.
        this.cfmBoxContainer.appendChild(this.cfmBox)

        // Appending box container to the layer.
        this.cfmLayer.appendChild(this.cfmBoxContainer)

        // Appending layer to the body.
        document.body.appendChild(this.cfmLayer)

        // Showing up new instance.
        this.cfmShowUpStructure()
    }

    this.cfmShowUpStructure = function()
    {
        var self = this

        setTimeout(function() {
            self.cfmLayer.classList.add('cfm-visible')
            self.cfmBoxContainer.classList.add('cfm-visible')
        }, 50)

        // Buttons click event.
        function confirmed()
        {
            document.removeEventListener('keyup', isEnterOrEscapePressed)

            self.cfmHideStructure()

            setTimeout(function() {
                self.cfmLayer.remove()

                if (typeof self.onConfirm === 'function') {
                    self.onConfirm()
                }
            }, self.getElementTransitionDuration(self.cfmBoxContainer))
        }
        self.cfmBtnConfirm.addEventListener('click', confirmed)

        function canceled()
        {
            document.removeEventListener('keyup', isEnterOrEscapePressed)

            self.cfmHideStructure()

            setTimeout(function() {
                self.cfmLayer.remove()

                if (typeof self.onCancel === 'function') {
                    self.onCancel()
                }
            }, self.getElementTransitionDuration(self.cfmBoxContainer))
        }
        self.cfmBtnCancel.addEventListener('click', canceled)

        function isEnterOrEscapePressed(e)
        {
            document.removeEventListener('keyup', isEnterOrEscapePressed)

            if (e.keyCode == 27) {
                return canceled()
            }

            if (e.keyCode == 13) {
                return confirmed()
            }
        }
        document.addEventListener('keyup', isEnterOrEscapePressed)
    }

    this.cfmHideStructure = function()
    {
        this.cfmLayer.classList.remove('cfm-visible')
        this.cfmBoxContainer.classList.remove('cfm-visible')

    }

    this.getElementTransitionDuration = function(element)
    {
        var transitionDuration = getComputedStyle(element)['transitionDuration']
        var transitionDurationValue = parseFloat(transitionDuration, 10)

        // Converting to milliseconds.
        if (! transitionDuration.match(/ms/)) {
            transitionDurationValue *= 1000
        }

        // Add extra time to prevent loading issues.
        transitionDurationValue += 50

        return transitionDurationValue
    }

    // Building a new instance.
    this.cfmBuildStructure()
}
